import Cookies from "js-cookie";

window.addEventListener("load", () => {
  const cookiesNotification = Cookies.get("cookies-notification");
  if (cookiesNotification) return;

  const wrapper = document.createElement("div");
  wrapper.classList.add("cookies-notification");
  wrapper.textContent =
    "We use cookies (and other similar technologies) to collect data to improve your experience.";
  const acceptButton = document.createElement("button");
  acceptButton.classList.add("btn");
  acceptButton.classList.add("btn-secondary");
  acceptButton.classList.add("btn-small");
  acceptButton.textContent = "Accept";
  acceptButton.addEventListener("click", () => {
    document.body.removeChild(wrapper);
    Cookies.set("cookies-notification", "true");
  });
  wrapper.appendChild(acceptButton);
  document.body.appendChild(wrapper);
});
