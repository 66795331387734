// A fun ascii message on opening up the chrome dev tools console
/**
 *  __  __                   __  __
 * /\ \/\ \                 /\ \/\ \
 * \ \ \ \ \     __      ___\ \ `\\ \    ___   _____   _____      __    ___
 *  \ \ \ \ \  /'__`\  /' _ `\ \ , ` \  / __`\/\ '__`\/\ '__`\  /'__`\/' _ `\
 *   \ \ \_/ \/\ \L\.\_/\ \/\ \ \ \`\ \/\ \L\ \ \ \L\ \ \ \L\ \/\  __//\ \/\ \
 *    \ `\___/\ \__/.\_\ \_\ \_\ \_\ \_\ \____/\ \ ,__/\ \ ,__/\ \____\ \_\ \_\
 *     `\/__/  \/__/\/_/\/_/\/_/\/_/\/_/\/___/  \ \ \/  \ \ \/  \/____/\/_/\/_/
 *                                               \ \_\   \ \_\
 *                                                \/_/    \/_/
 */

document.addEventListener('DOMContentLoaded', function() {
  console.log(`%c
  __  __                   __  __
 /\\ \\/\\ \\                 /\\ \\/\\ \\
 \\ \\ \\ \\ \\     __      ___\\ \\ \`\\\\ \\    ___   _____   _____      __    ___
  \\ \\ \\ \\ \\  /'__\`\\  /' _ \`\\ \\ , \` \\  / __\`\\/\\ '__\`\\/\\ '__\`\\  /'__\`\\/' _ \`\\
   \\ \\ \\_/ \\/\\ \\L\\.\\_/\\ \\/\\ \\ \\ \\\`\\ \\/\\ \\L\\ \\ \\ \\L\\ \\ \\ \\L\\ \\/\\  __//\\ \\/\\ \\
    \\ \`\\___/\\ \\__/.\\_\\ \\_\\ \\_\\ \\_\\ \\_\\ \\____/\\ \\ ,__/\\ \\ ,__/\\ \\____\\ \\_\\ \\_\\
     \`\\/__/  \\/__/\\/_/\\/_/\\/_/\\/_/\\/_/\\/___/  \\ \\ \\/  \\ \\ \\/  \\/____/\\/_/\\/_/
                                               \\ \\_\\   \\ \\_\\
                                                \\/_/    \\/_/
  `, 'font-family: monospace;');
});
